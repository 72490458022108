import React, {Component} from 'react';
import $ from 'jquery';

class OurJourney extends Component {
    constructor(props) {
        super(props);
        // this.state={
        //   first:true,
        //   second:false
        // }
        this.state = {
            scroll: false
        };
    }

    RightClick = () => {

        let scrollLeft = $('.home-front-journey').scrollLeft();

        if (scrollLeft !== 700) {
            var pos = $('.home-front-journey').scrollLeft() + 300;
            $('.home-front-journey').animate({scrollLeft: pos}, 500);
            // $('.home-front-journey').scrollLeft(pos);
        }
    };
    LeftClick = () => {
        var pos = $('.home-front-journey').scrollLeft() - 300;
        $('.home-front-journey').animate({scrollLeft: pos}, 500);
        // $('.home-front-journey').scrollLeft(pos);
    };

    render() {
        return (
            <div>
                <div className='hideOnTab d-flex align-items-center justify-space-between'>
                    <div className='journey-title'>
                        <img src={require('../../../../static/assets/img/journey.jpg')} alt=''/>
                    </div>
                    <div className='position-relative jouery-leaf-block'>
                        <div className='home-front-journey'>
                            {/* <div className={this.state.first?"jouery-thum":"jouery-thum second-thum"}></div> */}
                            <div className='jouery-thum'></div>

                            <div className='d-flex flex-wrap jouerny-main-block'>
                                <div className='our-journey-steps'>
                                    {/* {this.state.first ?
                                     ( <> */}
                                        <div className='our-journey-scroll-block'>
                                        <div className='steps-jour d-flex align-items-center'>
                                        <div className='width-full'>
                                        <h3 className='primary-text'>2019</h3>
                                        <p>21 GJEPC awards in 17 consecutive years</p>
                                        </div>
                                        </div>
                                        <div className='steps-jour-dash-shapret'>
                                        <div className='connect-box relative'>
                                        <img src={require('../../../../static/assets/svg/index/dash-i.svg')} alt=''/>
                                        </div>
                                        </div>
                                        </div>
                                        <div className='our-journey-scroll-block'>
                                        <div className='steps-jour d-flex align-items-center'>
                                        <div className='width-full'>
                                        <h3 className='primary-text'>2017</h3>
                                        <p>Hon. Prime Minister Of india Shri Narendra Modi Inaugurated Hk
                                        Hub</p>
                                        </div>
                                        </div>
                                        <div className='steps-jour-dash-shapret'>
                                        <div className='connect-box relative'>
                                        <img src={require('../../../../static/assets/svg/index/dash-i.svg')} alt=''/>
                                        </div>
                                        </div>
                                        </div>
                                        <div className='our-journey-scroll-block'>
                                        <div className='steps-jour d-flex align-items-center'>
                                        <div className='width-full'>
                                        <h3 className='primary-text'>2015</h3>
                                        <p>Jna Award Hong Kong</p>
                                        </div>
                                        </div>
                                        <div className='steps-jour-dash-shapret'>
                                        <div className='connect-box relative'>
                                        <img src={require('../../../../static/assets/svg/index/dash-i.svg')} alt=''/>
                                        </div>
                                        </div>
                                        </div>
                                        <div className='our-journey-scroll-block'>
                                        <div className='steps-jour d-flex align-items-center'>
                                        <div className='width-full'>
                                        <h3 className='primary-text'>2013-14</h3>
                                        <p>Achieved 100% sales growth with the group turnover of $800million</p>
                                        </div>
                                        </div>
                                        <div className='steps-jour-dash-shapret'>
                                        <div className='connect-box relative'>
                                        <img src={require('../../../../static/assets/svg/index/dash-i.svg')} alt=''/>
                                        </div>
                                        </div>
                                        </div>

                                        <div className='our-journey-scroll-block'>
                                        <div className='steps-jour d-flex align-items-center'>
                                        <div className='width-full'>
                                        <h3 className='primary-text'>2013</h3>
                                        <p>Headoffice at The Capital, Mumbai</p>
                                        </div>
                                        </div>
                                        <div className='steps-jour-dash-shapret'>
                                        <div className='connect-box relative'>
                                        <img src={require('../../../../static/assets/svg/index/dash-i.svg')} alt=''/>
                                        </div>
                                        </div>
                                        </div>

                                        <div className='our-journey-scroll-block'>
                                        <div className='steps-jour d-flex align-items-center'>
                                        <div className='width-full'>
                                        <h3 className='primary-text'>2012</h3>
                                        <p>Awarded De Beers Sightholder Status, Rio Tinto Select Diamantaire</p>
                                        </div>
                                        </div>
                                        <div className='steps-jour-dash-shapret'>
                                        <div className='connect-box relative'>
                                        <img src={require('../../../../static/assets/svg/index/dash-i.svg')} alt=''/>
                                        </div>
                                        </div>
                                        </div>

                                        <div className='our-journey-scroll-block'>
                                        <div className='steps-jour d-flex align-items-center'>
                                        <div className='width-full'>
                                        <h3 className='primary-text'>2009</h3>
                                        <p>Kisna Manufacturing Unit moved to Surat</p>
                                        </div>
                                        </div>
                                        <div className='steps-jour-dash-shapret'>
                                        <div className='connect-box relative'>
                                        <img src={require('../../../../static/assets/svg/index/dash-i.svg')} alt=''/>
                                        </div>
                                        </div>
                                        </div>

                                        <div className='our-journey-scroll-block'>
                                        <div className='steps-jour d-flex align-items-center'>
                                        <div className='width-full'>
                                        <h3 className='primary-text'>2006</h3>
                                        <p>Direct Client of Alrosa</p>
                                        </div>
                                        </div>
                                        <div className='steps-jour-dash-shapret'>
                                        <div className='connect-box relative'>
                                        <img src={require('../../../../static/assets/svg/index/dash-i.svg')} alt=''/>
                                        </div>
                                        </div>
                                        </div>

                                        <div className='our-journey-scroll-block'>
                                        <div className='steps-jour d-flex align-items-center'>
                                        <div className='width-full'>
                                        <h3 className='primary-text'>2002</h3>
                                        <p>H.K.Jewels Pvt.ltd, Mumbai</p>
                                        </div>
                                        </div>
                                        <div className='steps-jour-dash-shapret'>
                                        <div className='connect-box relative'>
                                        <img src={require('../../../../static/assets/svg/index/dash-i.svg')} alt=''/>
                                        </div>
                                        </div>
                                        </div>

                                        <div className='our-journey-scroll-block'>
                                        <div className='steps-jour d-flex align-items-center'>
                                        <div className='width-full'>
                                        <h3 className='primary-text'>1992</h3>
                                        <p>Established Hari Krishna Exports, Surat and Mumbai</p>
                                        </div>
                                        </div>
                                        <div className='steps-jour-dash-shapret'>
                                        <div className='connect-box relative'>
                                        <img src={require('../../../../static/assets/svg/index/dash-i.svg')} alt=''/>
                                        </div>
                                        </div>
                                        </div>
                                    {/* </>
                                     ):( */}
                                    {/* <> */}



                                    {/* </>
                                     )} */}
                                </div>
                                <div className='our-journey-steps'>
                                    {/* {this.state.first?
                                     <> */}
                                        <div className='our-journey-scroll-block'>
                                        <div className='steps-jour d-flex align-items-center'>
                                        <div className='width-full'>
                                        <h3 className='primary-text'>2018</h3>
                                        <p>Hon. Prime Minister of India Shri Narendra Modi and Hon. Chief Minister of Maharashtra Shri Devendra Fadnavis inaugurated skill India incentive ceremony.</p>
                                        </div>
                                        </div>
                                        <div className='steps-jour-dash-shapret'>
                                        <div className='connect-box relative'>
                                        <img src={require('../../../../static/assets/svg/index/dash-i.svg')} alt=''/>
                                        </div>
                                        </div>
                                        </div>
                                        <div className='our-journey-scroll-block'>
                                        <div className='steps-jour d-flex align-items-center'>
                                        <div className='width-full'>
                                        <h3 className='primary-text'>2016</h3>
                                        <p>Hari Krishna Exports PVT.LTD., Mumbai(Seepz)</p>
                                        </div>
                                        </div>
                                        <div className='steps-jour-dash-shapret'>
                                        <div className='connect-box relative'>
                                        <img src={require('../../../../static/assets/svg/index/dash-i.svg')} alt=''/>
                                        </div>
                                        </div>
                                        </div>
                                        <div className='our-journey-scroll-block'>
                                        <div className='steps-jour d-flex align-items-center'>
                                        <div className='width-full'>
                                        <h3 className='primary-text'>2014</h3>
                                        <p>Imperial Jewels, Mumbai</p>
                                        </div>
                                        </div>
                                        <div className='steps-jour-dash-shapret'>
                                        <div className='connect-box relative'>
                                        <img src={require('../../../../static/assets/svg/index/dash-i.svg')} alt=''/>
                                        </div>
                                        </div>
                                        </div>

                                        <div className='our-journey-scroll-block'>
                                        <div className='steps-jour d-flex align-items-center'>
                                        <div className='width-full'>
                                        <h3 className='primary-text'>2014</h3>
                                        <p>Awarded Four GJEPC Awards Selected as DDC Diamantaire</p>
                                        </div>
                                        </div>
                                        <div className='steps-jour-dash-shapret'>
                                        <div className='connect-box relative'>
                                        <img src={require('../../../../static/assets/svg/index/dash-i.svg')} alt=''/>
                                        </div>
                                        </div>
                                        </div>

                                        <div className='our-journey-scroll-block'>
                                        <div className='steps-jour d-flex align-items-center'>
                                        <div className='width-full'>
                                        <h3 className='primary-text'>2012</h3>
                                        <p>Certified member of RJC, Established Unity Jewels, Mumbai</p>
                                        </div>
                                        </div>
                                        <div className='steps-jour-dash-shapret'>
                                        <div className='connect-box relative'>
                                        <img src={require('../../../../static/assets/svg/index/dash-i.svg')} alt=''/>
                                        </div>
                                        </div>
                                        </div>

                                        <div className='our-journey-scroll-block'>
                                        <div className='steps-jour d-flex align-items-center'>
                                        <div className='width-full'>
                                        <h3 className='primary-text'>2011</h3>
                                        <p>Official Supplier of Canada Mark</p>
                                        </div>
                                        </div>
                                        <div className='steps-jour-dash-shapret'>
                                        <div className='connect-box relative'>
                                        <img src={require('../../../../static/assets/svg/index/dash-i.svg')} alt=''/>
                                        </div>
                                        </div>
                                        </div>

                                        <div className='our-journey-scroll-block'>
                                        <div className='steps-jour d-flex align-items-center'>
                                        <div className='width-full'>
                                        <h3 className='primary-text'>2008</h3>
                                        <p>Established H.K. Campus (Manufacturing Unit), Surat</p>
                                        </div>
                                        </div>
                                        <div className='steps-jour-dash-shapret'>
                                        <div className='connect-box relative'>
                                        <img src={require('../../../../static/assets/svg/index/dash-i.svg')} alt=''/>
                                        </div>
                                        </div>
                                        </div>

                                        <div className='our-journey-scroll-block'>
                                        <div className='steps-jour d-flex align-items-center'>
                                        <div className='width-full'>
                                        <h3 className='primary-text'>2005</h3>
                                        <p>Established H.K Designs, Mumbai Launched Kisna, Mumbai</p>
                                        </div>
                                        </div>
                                        <div className='steps-jour-dash-shapret'>
                                        <div className='connect-box relative'>
                                        <img src={require('../../../../static/assets/svg/index/dash-i.svg')} alt=''/>
                                        </div>
                                        </div>
                                        </div>

                                        <div className='our-journey-scroll-block'>
                                        <div className='steps-jour d-flex align-items-center'>
                                        <div className='width-full'>
                                        <h3 className='primary-text'>1996</h3>
                                        <p>Inception of Hari Krishna Charitable trust, Surat</p>
                                        </div>
                                        </div>
                                        <div className='steps-jour-dash-shapret'>
                                        <div className='connect-box relative'>
                                        <img src={require('../../../../static/assets/svg/index/dash-i.svg')} alt=''/>
                                        </div>
                                        </div>
                                        </div>

                                    {/*
                                     </>
                                     :
                                     <> */}
                                    {/* <div className='our-journey-scroll-block'>
                                     <div className='steps-jour d-flex align-items-center'>
                                     <div className='width-full'>
                                     <h3 className='primary-text'>2012</h3>
                                     <p>Awarded De Beers Sightholder Status, Rio Tinto Select Diamantaire</p>
                                     </div>
                                     </div>
                                     <div className='steps-jour-dash-shapret'>
                                     <div className='connect-box relative'>
                                     <img src={require('../../../assets/svg/index/dash-i.svg')} alt='' />
                                     </div>
                                     </div>
                                     </div>*/}


                                    {/*
                                     </>
                                     }
                                     */}
                                </div>
                            </div>
                        </div>
                        <div>
                            {/* {
                             this.state.first?  */}
                            {/* <button className="next journey-next" onClick={()=> this.setState({first:false})}> </button> */}
                                <button className='next journey-next' onClick={this.RightClick}></button>

                            {/* // : */}
                            {/* <button className="prev journey-prev" onClick={()=> this.setState({first:true})}></button> */}
                                <button className='prev journey-prev' onClick={this.LeftClick}></button>

                            {/* } */}
                        </div>
                        {/* <div className="view-more text-center mt-30">
                         <a href="/hk-achievements" className="btn btn-assent white-text">View More</a>
                         </div>*/}
                    </div>
                </div>
                <div className="showOnTab">

                    <h2 className="show-text sub-head padd-max js-type-animation-alt">Our Journey</h2>
                    <div className="our-journey-steps d-flex align-items-center">
                        <div className="steps-jour d-flex align-items-center">
                            <div className="width-full">
                                <h3 className="primary-text">1992</h3>
                                <p>Established Hari Krishna Exports, Surat and Mumbai</p>
                            </div>
                        </div>
                        <div className="steps-jour d-flex align-items-center">
                            <div className="width-full">
                                <h3 className="primary-text">1996</h3>
                                <p>Inception of Hari Krishna Charitable trust, Surat</p>
                            </div>
                        </div>
                        <div className="steps-jour d-flex align-items-center">
                            <div className="width-full">
                                <h3 className="primary-text">2002</h3>
                                <p>H.K.Jewels Pvt.ltd, Mumbai</p>
                            </div>
                        </div>
                        <div className="steps-jour d-flex align-items-center">
                            <div className="width-full">
                                <h3 className="primary-text">2005</h3>
                                <p>Established H.K Designs, Mumbai Launched Kisna, Mumbai</p>
                            </div>
                        </div>
                        <div className="steps-jour d-flex align-items-center">
                            <div className="width-full">
                                <h3 className="primary-text">2006</h3>
                                <p>Direct Client of Alrosa</p>
                            </div>
                        </div>
                        <div className="steps-jour d-flex align-items-center">
                            <div className="width-full">
                                <h3 className="primary-text">2008</h3>
                                <p>Established H.K. Campus (Manufacturing Unit), Sura</p>
                            </div>
                        </div>
                        <div className="steps-jour d-flex align-items-center">
                            <div className="width-full">
                                <h3 className="primary-text">2009</h3>
                                <p>Kisna Manufacturing Unit moved to Surat</p>
                            </div>
                        </div>
                        <div className="steps-jour d-flex align-items-center">
                            <div className="width-full">
                                <h3 className="primary-text">2011</h3>
                                <p>Official Supplier of Canada Mark</p>
                            </div>
                        </div>
                        <div className="steps-jour d-flex align-items-center">
                            <div className="width-full">
                                <h3 className="primary-text">2012</h3>
                                <p>Awarded De Beers Sightholder Status, Rio Tinto Select Diamantaire</p>
                            </div>
                        </div>
                        <div className="steps-jour d-flex align-items-center">
                            <div className="width-full">
                                <h3 className="primary-text">2012</h3>
                                <p>Certified member of RJC, Established Unity Jewels, Mumbai</p>
                            </div>
                        </div>
                        <div className="steps-jour d-flex align-items-center">
                            <div className="width-full">
                                <h3 className="primary-text">2013</h3>
                                <p>Headoffice at The Capital, Mumbai</p>
                            </div>
                        </div>
                        <div className="steps-jour d-flex align-items-center">
                            <div className="width-full">
                                <h3 className="primary-text">2014</h3>
                                <p>Awarded Four GJEPC Awards Selected as DDC Diamantaire</p>
                            </div>
                        </div>
                        <div className="steps-jour d-flex align-items-center">
                            <div className="width-full">
                                <h3 className="primary-text">2013-14</h3>
                                <p>Achieved 100% sales growth with the group turnover of $800million</p>
                            </div>
                        </div>
                        <div className="steps-jour d-flex align-items-center">
                            <div className="width-full">
                                <h3 className="primary-text">2014</h3>
                                <p>Imperial Jewels, Mumbai</p>
                            </div>
                        </div>
                        <div className="steps-jour d-flex align-items-center">
                            <div className="width-full">
                                <h3 className="primary-text">2015</h3>
                                <p>Jna Award Hong Kong</p>
                            </div>
                        </div>
                        <div className="steps-jour d-flex align-items-center">
                            <div className="width-full">
                                <h3 className="primary-text">2016</h3>
                                <p>Hari Krishna Exports PVT.LTD., Mumbai(Seepz)</p>
                            </div>
                        </div>
                        <div className="steps-jour d-flex align-items-center">
                            <div className="width-full">
                                <h3 className="primary-text">2017</h3>
                                <p>Hon. Prime Minister Of india Shri Narendra Modi Inaugurated Hk Hub</p>
                            </div>
                        </div>
                        <div className="steps-jour d-flex align-items-center">
                            <div className="width-full">
                                <h3 className="primary-text">2018</h3>
                                <p>20 Gjepc Awards in 16 Consecutive Years</p>
                            </div>
                        </div>
                        <div className="steps-jour d-flex align-items-center">
                            <div className="width-full">
                                <h3 className="primary-text">2018</h3>
                                <p>Hon. PM Of Indian Shri Narendra Modi And Hon. CM Of Maharashtra Shri Devendra
                                    Fadnavis Inaugurated SIIC Skill India Incentive Ceremony.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default OurJourney;
