import React, {Component} from 'react'
import Slider from 'react-slick'
import {Col, Icon, Modal, Row} from 'antd';
import '../../static/assets/home-value.css'
import $ from 'jquery'
import OurJourney from './FrontStatic/common/Ourjourney'
import FrontFooter from './FrontStatic/common/Front-Footer'
import FrontWrapper from './FrontStatic/common/FrontWrapper'
import {BASE_URL_ATTACH} from "../constants/Common";
import IntlMessages from '../util/IntlMessages'
import CountDown from "../components/common/couter.js";
import LazyImage from './common/lazyImage';
import Cookies from "js-cookie";
import moment from 'moment';
import PlayIcon from "../../static/assets/svg/index/playIcon.svg"
import ExpandVideo from "../../static/assets/svg/index/expandVideo.svg"
import FullVideo from "../../static/assets/svg/index/fullVideo.svg"
import WhyBg from "../../static/assets/img/static/why-bg.png"
import HKExport from "../../static/assets/img/hk-export.png"
import HKJewel from "../../static/assets/img/hk-jewel.png"
import KisnaJewel from "../../static/assets/img/kisna.png"
import MyDiamondStory from "../../static/assets/img/mds.png"
import ServiceImg from "../../static/assets/img/service.svg"
import MobileBank from "../../static/assets/img/mobileBank2.png"
import SplashVBottom from "../../static/assets/svg/index/splashBottom.svg"
import OfflineLink1 from "../../static/assets/svg/index/offlineLink1.svg"
import PlayButton from "../../static/assets/svg/index/playButton.svg"
import HuaweiIcon from "../../static/assets/svg/index/huawei-icon.svg"
import VivoBlackBlue from "../../static/assets/svg/index/vivo-blackBlue.svg"
import OppoBlackBlue from "../../static/assets/svg/index/oppo-blackBlueBig.svg"
import UtilService from "../services/util";


/* eslint jsx-a11y/anchor-is-valid: 0 */
const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000
};


class index extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            homePopupData: {}
        };
        this.interval = null
    }

    componentDidMount() {
        (function ($) {
            var $el = {};

            // $(document).ready(domReady)

            function cacheDom() {
                $el.htmlNbody = $('body, html');
                $el.html = $('html');
                $el.body = $('body');

                //HomeSLider
                $el.mainSlider = $('#main-slider');
                $el.mainSliderInit = $('.js_home_slider_init');
                $el.circleSvgHover = $('.circle-svg-hover');
                $el.mainSliderPerspectiveHover = $('.js_main-slider_perspective_hover');
                $el.mainSliderArrow = $('.js_main_slider_arrow');

                //Collections
                $el.collections = $('#collections');
                $el.collectionsGrid = $('.c-grid');
                $el.collectionsGridItem = $('.c-grid__item');
                $el.collectionsGridAnchor = $('.c-grid__anchor');
                $el.collectionsTabs = $('.c-tabs');
                $el.mainSliderMenu = $('#main-slider__menu');
                $el.gallerySlider = $('.image-content__slider')
            }

            function domReady() {
                cacheDom();
                initCollections();
                initAll()
            }

            domReady();

            function initCollections() {
                if ($('#collections').length) {
                }
            }

            function initAll() {
                if ($el.mainSlider.length) {
                    new HomeSlider()
                }
            }

            function HomeSlider() {

                this.slider = $el.mainSliderInit;
                this.sliderArrow = $el.mainSliderArrow;
                this.sliderMenuItem = $el.mainSliderMenu.find('li');
                this.textSlides = $('.section');

                //Arrows for touch screens
                this.touchArrowDown = $('.touch-arrow--down');
                this.touchArrowUp = $('.touch-arrow--up');

                this.init()
            }

            HomeSlider.prototype.init = function () {
                var that = this;

                this.textSlides
                    .find('.main-slider__texthead .main-slider__texthead__title')
                    .each(function () {
                        $(this).html(
                            $(this)
                                .text()
                                .replace(
                                    /([^\s]+)/g,
                                    '<span className="main-slider__texthead__title__text"><span>$1</span></span>',
                                ),
                        )
                    });

                this.slider.pagepiling({
                    scrollingSpeed: 100,
                    navigation: false,
                    afterRender: function () {
                        var $activeSection = $('.main-slider__content-single.active');

                        $activeSection
                            .removeClass('prev-section next-section')
                            .prevAll()
                            .addClass('prev-section')
                            .end()
                            .nextAll()
                            .addClass('next-section')
                    },
                    onLeave: function (index, nextIndex, direction) {
                        var $activeSection = $('.main-slider__content-single.active');

                        $activeSection
                            .removeClass('prev-section next-section')
                            .prevAll()
                            .addClass('prev-section')
                            .end()
                            .nextAll()
                            .addClass('next-section');

                        $el.mainSliderMenu
                            .find('li')
                            .eq($activeSection.index())
                            .addClass('active')
                            .siblings()
                            .removeClass('active');

                        var $previousSection = $(
                            '.main-slider__content-single.prev-section',
                            ),
                            $nextSection = $('.main-slider__content-single.next-section');

                        if (direction === 'down') {
                            $previousSection.addClass('complete')
                        }

                        if (direction === 'up') {
                            $nextSection.removeClass('complete')
                        }

                        if (direction === 'up' && $activeSection.hasClass('full')) {
                            $activeSection.removeClass('complete')
                        }

                        // if ($('.section.full').hasClass('delay-animation')) {
                        //     Snap('#bigCircle').animate({
                        //         r: $(window).width() / 2
                        //     }, 400);
                        // }

                        if ($('.section.full').hasClass('prev-section')) {
                            if ($el.body.hasClass('light-section')) {
                                $el.body.removeClass('light-section')
                            }
                        }

                        if (
                            index === $('.main-slider__content-single').length - 1 &&
                            direction === 'down'
                        ) {
                            $el.body.addClass('last-section-active')
                        } else {
                            $el.body.removeClass('last-section-active')
                        }
                    },
                    afterLoad: function (anchorLink, index) {
                        var $activeSection = $('.main-slider__content-single.active');

                        $activeSection
                            .removeClass('prev-section next-section')
                            .prevAll()
                            .addClass('prev-section')
                            .end()
                            .nextAll()
                            .addClass('next-section');

                        if (index === $('.section').length) {
                            that.sliderArrow.addClass('moveUp')
                        } else {
                            that.sliderArrow.removeClass('moveUp')
                        }
                    },
                });
                this.slider.pagepiling.setAllowScrolling(false);
                this.sliderArrow.click(function () {
                    if ($(this).hasClass('moveUp')) {
                        $.fn.pagepiling.moveSectionUp()
                    } else {
                        $.fn.pagepiling.moveSectionDown()
                    }
                });

                this.touchArrowDown.click(function () {
                    $.fn.pagepiling.moveSectionDown()
                });

                this.touchArrowUp.click(function () {
                    $.fn.pagepiling.moveSectionUp()
                });

                this.sliderMenuItem.click(function () {
                    if (!$(this).hasClass('active')) {
                        var index = $(this).index();

                        $.fn.pagepiling.moveTo(index + 1)
                    }

                    return false
                })
            };

            function Collections() {
                this.collections = $('#collections');
                this.grid = $('.c-grid');
                this.gridTabs = $('.c-tabs');
                this.gridItem = $('.c-grid__item');
                this.popupTrigger = $('.c-grid__anchor');
                this.popupOpen = false;
                this.popupEnquiry = false;

                this.init()
            }

            Collections.prototype.init = function () {
                var that = this;

                $('.lazyload').Lazy({
                    // your configuration goes here
                    scrollDirection: 'vertical',
                    effect: 'fadeIn',
                    visibleOnly: true,
                    beforeLoad: function (element) {
                        // called before an elements gets handled
                        that.grid.masonry({
                            itemSelector: '.c-grid__item',
                            percentPosition: true,
                            horizontalOrder: true,
                        });

                        $(element)
                            .closest('.c-grid__item')
                            .addClass('lazyloading')
                    },
                    afterLoad: function (element) {
                        // called after an element was successfully handled
                        $(element)
                            .closest('.c-grid__item')
                            .addClass('lazyloaded');

                        that.grid.masonry('layout');

                        $el.body.removeClass('o-hidden');

                        that.grid.on('layoutComplete', function () {
                            setTimeout(function () {
                                $(element)
                                    .closest('.c-grid__item')
                                    .addClass('inn');
                                that.gridTabs.addClass('inn')

                            }, 1000)
                        })
                    },
                })
            }
        })($);

        // $(document).ready(function() {
        $('#loginform').click(function () {
            $('.login').fadeToggle('slow');
            $('.body-overlay').fadeToggle('slow')
            //$(this).toggleClass('green');
        });

        $(document).mouseup(function (e) {
            var container = $('.login');
            var overlay = $('.body-overlay');

            if (
                !container.is(e.target) && // if the target of the click isn't the container...
                container.has(e.target).length === 0 &&
                overlay.is(e.target)
            ) {
                // ... nor a descendant of the container
                overlay.fadeToggle('slow');
                container.fadeToggle('slow')
                //  $('#loginform').removeClass('green');
            }
        });

        // })

        function checkWidth(init) {
            /*If browser resized, check width again */
            if ($(window).width() < 514) {
                $('body').addClass('mobile phone')
            } else {
                if (!init) {
                    $('body').removeClass('mobile, phone')
                }
            }
        }

        // $(document).ready(function() {
        checkWidth(true);
        $(window).resize(function () {
            checkWidth(false)
        });
        // })
        var scrollSpeed = 10;

        // set the default position
        var current = 0;

        // set the direction
        var direction = 'h';

        function bgscroll() {
            // 1 pixel row at a time
            current -= 1;

            // move the background with backgrond-position css properties
            $('.video-image-wrapper').css(
                'backgroundPosition',
                direction === 'h' ? current + 'px 44%' : '0 ' + current + 'px',
            );
            $('.rotate360').css(
                'backgroundPosition',
                direction === 'h' ? current + 'px 44%' : '0 ' + current + 'px',
            )
        }

        //Calls the scrolling function repeatedly
        this.interval = setInterval(bgscroll, scrollSpeed);

        /*var imageAddr = require("../../static/img/11.jpg");
         var downloadSize = 319000;*/
        // $(document).ready(function() {
        // window.setTimeout(MeasureConnectionSpeed, 1);
        //
        // // })
        //
        // function MeasureConnectionSpeed() {
        //     var startTime, endTime;
        //     var download = new Image();
        //     download.onload = function () {
        //         endTime = new Date().getTime();
        //         showResults()
        //     };
        //
        //     startTime = new Date().getTime();
        //     var cacheBuster = '?nnn=' + startTime;
        //     download.src = imageAddr + cacheBuster;
        //
        //     function showResults() {
        //         var duration = (endTime - startTime) / 1000;
        //         var bitsLoaded = downloadSize * 8;
        //         var speedBps = (bitsLoaded / duration).toFixed(2);
        //         var speedKbps = (speedBps / 1024).toFixed(2);
        //         var speedMbps = (speedKbps / 1024).toFixed(2);
        //         //code your need according to different network speed..:D
        //         if (speedMbps >= 1) {
        //             $('.bg-mission').addClass('rotate360')
        //         }
        //     }
        // }
        this.fetchdata();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    fetchdata = () => {
        let obj = {
            method: "post",
            url: "/apis/banner/paginate",
            request: {
                filter: {type: [7, 8], isActive: true}
            }
        };
        UtilService.callApi(obj, (err, data) => {

            if (data.data && data.data.popup && data.data.popup.id) {

                this.setState({
                    homePopupData: data.data.popup
                });

                if (!Cookies.get('homePopupSeen')) {
                    this.setState({showModal: true})
                }
            }
        });
    };


    handleModalFn = () => {
        this.setState({showModal: false});
        Cookies.set('homePopupSeen', true, {expires: 1})
    };

    render() {
        const {
            tour = {}, ourValues = {}, philosophy = {}, services = {},
            vision = {}, mission = {}, partners = {}, leadingEdge = {}, activities = {}
        } = this.props.fields;
        const targetDate = moment(this.state.homePopupData.toDateTime).format('MMM DD, YYYY');
        const targetTime = moment(this.state.homePopupData.toDateTime).format('LTS');


        return (
            <FrontWrapper location={this.props.location}>

                <Modal className="StaticModal" visible={this.state.showModal} onOk={this.handleModalFn}
                       onCancel={this.handleModalFn}>
                    <img alt={this.state.homePopupData.name}
                         src={BASE_URL_ATTACH + this.state.homePopupData.image}
                         className="DaskTopviewImage" style={{maxWidth: "100%"}}/>
                    <img alt={this.state.homePopupData.name}
                         src={BASE_URL_ATTACH + this.state.homePopupData.mobileBackgroundImage}
                         className="MobilewViewImage" style={{maxWidth: "100%"}}/>

                    {this.state.homePopupData && this.state.homePopupData.timertoDateTime && this.state.homePopupData.toDateTime &&
                    <CountDown targetDate={targetDate} targetTime={targetTime}/>}
                    {/* <CountDown targetDate="Jun 7, 2020" targetTime="11:59:59 PM" /> */}
                </Modal>
                <div className="scroll-content-wrapper" data-scrollbar>
                    <button className="soundwave-btn">
                        <div id="soundwave-on" className="soundwave" data-active="on"/>
                        <div id="soundwave-off" className="soundwave" data-active="off"/>
                        <div className="sound-feedback">
                            <span id="feedback-on">on</span>
                            <span id="feedback-off">off</span>
                        </div>
                    </button>
                    <div className="page-main page-current">
                        <div className="page-toload home-page" data-bodyclassname="home">
                            <header className="page-header">
                                <div className="video-image-wrapper" data-offset="30">
                                    <video
                                        style={{display: 'none'}}
                                        muted
                                        className="video-image-holder"
                                        loop
                                        // poster="imgs/video/landing-poster.jpg"
                                    >
                                        <source
                                            // src="imgs/video/couro-landing.mp4"
                                            type="video/mp4"
                                        />
                                        Your browser does not support the video tag.
                                    </video>
                                    <button
                                        className="play-video-hidden bottom"
                                        data-target=".video-image-holder"
                                    >
                                        <LazyImage src={PlayIcon} alt={""}/>
                                        <span className="pause-icon"/>

                                    </button>

                                    <div className="video-ui">
                                        <button className="play-video">
                                            <div id="play-bodymovin"/>
                                        </button>
                                        <div className="progress-bar-hit-zone">
                                            <span className="progress-bar-wrapper">
                                                <span className="progress-bar"/>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="video-image-wrapper-overlay"/>
                                    <div className="slugline" style={{display: 'none'}}>
                                        <div className="row text-center align-center">
                                            <div className="xxlarge-16">
                                                <h2>{tour.title}</h2>
                                                <div className="row align-center text-center">
                                                    <div className="xxlarge-3 xlarge-4 medium-6">
                                                        <h6 className="small-caps">
                                                            {tour.description}
                                                        </h6>
                                                        <h6 className="small-caps withLinkTour">
                                                            <a
                                                                href="/360"
                                                                target="_blank" rel="noopener noreferrer">
                                                                {tour.btnText}</a>
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="expand-video-wrapper"
                                        style={{display: 'none'}}
                                    >
                                        <button className="expand-video">
                                            <LazyImage
                                                src={ExpandVideo}
                                                alt={""}/>
                                            <span data-after="Expand video">Expand teaser</span>
                                        </button>

                                        <button className="full-process-btn">
                                            <LazyImage src={FullVideo} alt={""}/>
                                            <span>Full video</span>
                                        </button>
                                    </div>
                                    {/* <div className="page-title">
                                     <h2 />
                                     <img src={require('../assets/svg/index/playMobile.svg')} />
                                     <span className="scroll-line" />
                                     </div> */}
                                    <div id="scroll-helper">
                                        <span className="scroll-line"/>
                                        <span className="scroll-word">Scroll</span>
                                    </div>
                                </div>
                                <div className="text-wrapper row align-middle">
                                    <div className="canvas-wrapper">
                                        <div className="xxlarge-10 columns center-projects xsmall-12">
                                            <h2 className="title-text offset-text main-offset">
                                                {tour.title}
                                            </h2>
                                            <div className="text-left">
                                                <div className="xxlarge-16 xsmall-12">
                                                    <h6 className="small-caps">
                                                        {tour.description}
                                                    </h6>
                                                    <h6 className="small-caps withLinkTour">
                                                        <a href={`/360`}
                                                           target="_blank" rel="noopener noreferrer">{tour.btnText}</a>
                                                    </h6>
                                                </div>
                                            </div>
                                            <video muted loop className="home-video-canvas">
                                                <source
                                                    // src="imgs/video/diamond-jewellery.mp4"
                                                    type="video/mp4"
                                                />
                                                Your browser does not support the video tag.
                                            </video>
                                            <canvas
                                                id="canvas-header-home"
                                                style={{display: 'none'}}
                                            />
                                            <div className="splash-bottom">
                                                <LazyImage
                                                    src={SplashVBottom}
                                                    alt={""}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </header>
                            <main className="page-content" role="main">


                               {/**********************/}

                                {/*<section className="SingleDiamondsec">
                                    <div className="SingleDiamondsecInner">
                                        <div className="TwSecWithFlx">
                                            <div className="TwSameSz">
                                                <img src={require("../../static/img/diamond.png")} alt=""/>
                                            </div>
                                            <div className="TwSameSz">
                                                <h2>Lorem Ipsum is simply dummy</h2>
                                                <p>
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                                </p>
                                                <a>Know More</a>
                                            </div>
                                        </div>
                                    </div>
                                </section>*/}

                                {/* <section className="threeBlockOne">
                                    <div className="section-container">
                                        <div className="innerRowa">
                                            <div className="equalRoeCol">
                                                <div className="entryIng">
                                                    <img src={require("../../static/img/diamond-masterpiec.jpg")} alt=""/>
                                                </div>
                                                <div className="entryCont">
                                                    <h2>30.15 ct Masterpiece</h2>
                                                    <a>Discover More</a>
                                                </div>
                                            </div>
                                            <div className="equalRoeCol">
                                                <div className="entryIng">
                                                    <img src={require("../../static/img/diamond-masterpiec.jpg")} alt=""/>
                                                </div>
                                                <div className="entryCont">
                                                    <h2>30.15 ct Masterpiece</h2>
                                                    <a>Discover More</a>
                                                </div>
                                            </div>
                                            <div className="equalRoeCol">
                                                <div className="entryIng">
                                                    <img src={require("../../static/img/diamond-masterpiec.jpg")} alt=""/>
                                                </div>
                                                <div className="entryCont">
                                                    <h2>30.15 ct Masterpiece</h2>
                                                    <a>Discover More</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </section> */}

                               {/**********************/}

                                <div className="more-section">
                                    <div className="value-bg-img">
                                        <LazyImage src={WhyBg} alt=""/>
                                    </div>
                                    <div className="section-container">
                                        <div className="intro">
                                            <div className="more-section--heading">
                                                <h2>{ourValues.title}</h2>
                                                <p>
                                                    {ourValues.description}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="icons">
                                            {ourValues ?.values?.map((value,i) =>
                                                <>
                                                { i === 4 ? <div className="icon"/> : null   }
                                                <div className="icon">
                                                <div className={`more-section--animation ${i < 3 ? "show" : ""}`}>
                                                <LazyImage src={value.img.publicURL} alt=""/>
                                                {/*<LazyImage src={require("../assets/svg/05_svg.svg")} alt=""/>*/}
                                                <h4>{value.title}</h4>
                                                <p>
                                                {value.description}
                                                </p>
                                                </div>
                                                </div>
                                                </>
                                                )}
                                        </div>
                                    </div>
                                </div>


                                <section className="philosophy relative">
                                    <div className="container relative">
                                        <div className="page-heading text-center white-text mb-50">
                                            <IntlMessages id="app.OurPhilosophy"/>

                                        </div>

                                        <div className="slider-wrap">
                                            <div className="slider">
                                                <Slider {...settings}>
                                                    {philosophy ?.map((ph, i) => <div key={i} className="slide">
                                                        <h3 className="white-text">{ph.title}</h3>
                                                        <p>
                                                        {ph.description}
                                                        </p>
                                                        </div>)}
                                                </Slider>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section className="searvicesHome">
                                    <div className="container  section-container relative">
                                        <div className="servicesTwoSections d-xs-block">
                                            <div className="servicesLeft">
                                                <div className="serviceHead">
                                                    <h2 className="serviceTitle mb-xs-20">{services.title}</h2>
                                                    <p>
                                                        {services.description}
                                                    </p>
                                                </div>
                                                <div className="serviceContentArea">
                                                    <div className="plasticCards__type">
                                                        {services ?.serviceList?.map(service =>
                                                            <div className="plasticCards__typeItem">
                                                            <div className="plasticCards__typeHead">
                                                            <a className="plasticCards__typeTitle">{service.title}</a>
                                                            <div className="plasticCards__arrowIcon">
                                                            <Icon type="right"/>
                                                            </div>
                                                            </div>
                                                            <div
                                                            className="plasticCards__typeDescription textDescription">
                                                            {service.description}
                                                            <div className="plasticCards__shadow" />
                                                            </div>
                                                            </div>
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="servicesRight">

                                                <div className="serviceRows">
                                                    <a href="">
                                                        <LazyImage src={HKExport} alt=""/>
                                                    </a>
                                                </div>
                                                <div className="serviceRows">
                                                    <a href="https://www.kisna.com/" target="_blank">
                                                        <LazyImage src={KisnaJewel} alt=""/>
                                                    </a>
                                                    <a href="http://www.mydiamondstory.com/" target="_blank">
                                                        <LazyImage src={MyDiamondStory} alt=""/>
                                                    </a>
                                                </div>
                                                <div className="serviceRows">
                                                    <a href="">
                                                        <LazyImage src={HKJewel} alt=""/>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>


                                <div className="vision-mission-wrap">
                                    <div className="section-container">
                                        <Row type="flex">
                                            <Col xl={6} lg={12} xs={24} sm={12} className="vision-mission-img">
                                                <LazyImage src={vision.img.publicURL} alt=""/>
                                                {/*<LazyImage src={require("../assets/img/static/bg-vision.jpg")} alt="" />*/}
                                            </Col>
                                            <Col xl={6} lg={12} sm={12} className="vision-mission vision-block">
                                                <div className="v-content">
                                                    <h3>{vision.title}</h3>
                                                    <p>
                                                        {vision.description}
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col xl={6} lg={12} sm={12} className="vision-mission-img">
                                                <LazyImage src={mission.img.publicURL} alt=""/>
                                                {/*<LazyImage src={require("../assets/img/static/bg-mission.jpg")} alt=""/>*/}
                                            </Col>
                                            <Col xl={6} lg={12} sm={12} className="vision-mission mission-block">
                                                <div className="v-content">
                                                    <h3>{mission.title}</h3>
                                                    <p>
                                                        {mission.description}
                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>

                                <div className="section-container journy-countainer mobile-jorney">
                                    <OurJourney/>
                                </div>

                                <section className="activities no-padding mt-0 mb-0">
                                    <div className="slider-wrap">
                                        <div className="activity-slider section-container ">
                                            <div className="page-heading text-center mb-50">
                                                <span>Activities</span>
                                            </div>
                                            <Slider {...settings}>
                                                {activities ?.map((ac,i) => <div key={i}
                                                    className="activity-slide">
                                                    <div
                                                    className="activity-sld relative"
                                                    >
                                                    <div className="container relative activity-content-block">
                                                    <h2>
                                                    {ac.title}
                                                    </h2>
                                                    <p>{ac.description}
                                                    </p>

                                                    </div>
                                                    <Row type="flex" className="justify-end-flex">
                                                    <Col lg={20} sm={20} xs={20}>
                                                    <div className="activity-slider-img">
                                                    {/* <img  src={require("../assets/img/static/csr/1.png")} alt=""/> */}
                                                    <LazyImage
                                                    src={ac.img.publicURL}
                                                    alt=""/>
                                                    </div>
                                                    </Col>
                                                    </Row>
                                                    </div>
                                                    </div>)}
                                            </Slider>
                                        </div>
                                    </div>
                                </section>
                                <div className="section-container journy-countainer">
                                    <h2 className="show-text sub-head padd-max js-type-animation-alt">
                                        {partners.title}
                                    </h2>
                                    <div className="partners-container d-flex align-items-center j-space-between">
                                        {partners ?.partnerImages?.map(({url,img}) =>
                                            <a href={url}
                                            className="partners-logo">
                                            <LazyImage
                                            src={img.publicURL}
                                            alt={""}/>

                                            </a>
                                            )}
                                        {/* <a href="http://gss.debeersgroup.com/customer-directory/hari-krishna-exports-pvt-ltd"
                                         className="partners-logo">
                                         <LazyImage
                                         src={require('../assets/svg/index/dtc.png')}
                                         alt={""}/>

                                         </a>
                                         <a href="http://www.riotinto.com/" className="partners-logo">
                                         <LazyImage
                                         src={require('../assets/svg/index/riotinto-logo.svg')}
                                         alt={""}/>
                                         </a>
                                         <a href="https://www.ddmines.com/" className="partners-logo">
                                         <LazyImage src={require('../assets/svg/index/dominion.svg')} alt={""}/>
                                         </a>
                                         <a href="http://sales.alrosa.info/" className="partners-logo">
                                         <LazyImage
                                         src={require('../assets/svg/index/alrosa.png')}
                                         alt={""}/>
                                         </a>
                                         <a href="http://www.canadamark.com/" className="partners-logo">
                                         <LazyImage
                                         src={require('../assets/svg/index/canada-mark.svg')}
                                         alt={""}/>
                                         </a>
                                         // <div className="partners-logo">{svg pending}</div>
                                         <a href="http://www.forevermark.com/" className="partners-logo">
                                         <LazyImage
                                         src={require('../assets/svg/index/forevermark.svg')}
                                         alt={""}/>
                                         </a>
                                         <a href="http://www.responsiblejewellery.com/member/hari-krishna-exports/"
                                         className="partners-logo">
                                         <LazyImage
                                         src={require('../assets/svg/index/jeweller-counsil.svg')}
                                         alt={""}/>
                                         </a>
                                         <a href="https://www.worlddiamondcouncil.org/" className="partners-logo">
                                         <LazyImage
                                         src={require('../assets/img/diamond-counsil-logo.png')}
                                         alt={""}/>
                                         </a>*/}
                                    </div>
                                </div>

                                <section className="section mobileBank getAjaxArea mobileBank__visible">
                                    <div className="container-fluid">
                                        <div className="mainMobCont d-xs-block">
                                            <div className="mobConta">
                                                <div className="mobileBank__bigImg">
                                                    <LazyImage src={leadingEdge.img.publicURL} alt=""/>
                                                    <LazyImage src={MobileBank} alt=""/>
                                                </div>
                                            </div>
                                            <div className="mobConta">
                                                <div className="mobileBank__head">
                                                    <h2>
                                                        {leadingEdge.title}
                                                    </h2>
                                                    <p>{leadingEdge.description}</p>
                                                    <ul className="featured-points">
                                                        {leadingEdge ?.items?.map(({title,img}) =>
                                                            <li className="d-flex align-items-center">
                                                            <div className="ico-feature">
                                                            <LazyImage
                                                            src={img.publicURL}
                                                            alt={""}/>
                                                            </div>
                                                            <div className="feature-name">
                                                            {title}
                                                            {/*<IntlMessages id="app.Quickandeasysearch"/>*/}
                                                            </div>
                                                            </li>
                                                            )}
                                                        {/* <li className="d-flex align-items-center">
                                                         <div className="ico-feature">
                                                         <LazyImage
                                                         src={require('../assets/svg/index/quick-search.svg')}
                                                         alt={""}/>
                                                         </div>
                                                         <div className="feature-name">
                                                         <IntlMessages id="app.Quickandeasysearch"/>
                                                         </div>
                                                         </li>
                                                         <li className="d-flex align-items-center">
                                                         <div className="ico-feature">
                                                         <LazyImage
                                                         src={require('../assets/svg/index/review-eye.svg')}
                                                         alt={""}/>
                                                         </div>
                                                         <div className="feature-name">
                                                         <IntlMessages id="app.Reviewatyourconvenience"/>
                                                         </div>
                                                         </li>
                                                         <li className="d-flex align-items-center">
                                                         <div className="ico-feature">
                                                         <LazyImage
                                                         src={require('../assets/svg/index/buy-diamond.svg')}
                                                         alt={""}/>
                                                         </div>
                                                         <div className="feature-name">
                                                         <IntlMessages id="app.Buydiamondsinjust3clicks"/>

                                                         </div>
                                                         </li>
                                                         <li className="d-flex align-items-center">
                                                         <div className="ico-feature">
                                                         <LazyImage
                                                         src={require('../assets/svg/index/collection-diamonds.svg')}
                                                         alt={""}/>
                                                         </div>
                                                         <div className="feature-name">

                                                         <IntlMessages id="app.Collectionofover25 000diamonds"/>

                                                         </div>
                                                         </li>*/}
                                                    </ul>

                                                    <ul className="offline-links" style={{marginTop: '30px'}}>
                                                        <li>
                                                            <a target="_blank"
                                                               href="https://itunes.apple.com/in/app/hk.co/id1010715749?mt=8"
                                                               rel="noopener noreferrer">
                                                                <LazyImage
                                                                    src={OfflineLink1}
                                                                    alt={""}/>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a target="_blank"
                                                               href="https://play.google.com/store/apps/details?id=com.dnk.hkcodemo"
                                                               rel="noopener noreferrer">
                                                                <LazyImage
                                                                    src={PlayButton}
                                                                    alt={""}/>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a target="_blank"
                                                               href="http://appstore.huawei.com/app/C100137045"
                                                               rel="noopener noreferrer">
                                                                <LazyImage
                                                                    src={HuaweiIcon}
                                                                    alt={""}/>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a target="_blank"
                                                               href="market://details?id=com.dnk.hkcodemo"
                                                               type="vivo/launch"
                                                               rel="noopener noreferrer">
                                                                <LazyImage
                                                                    src={VivoBlackBlue}
                                                                    alt={""}/>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a target="_blank"
                                                               href="market://details?id=com.dnk.hkcodemo"
                                                               type="oppo/launch"
                                                               rel="noopener noreferrer">
                                                                <LazyImage
                                                                    src={OppoBlackBlue}
                                                                    alt={""}/>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <FrontFooter/>
                            </main>
                            <div className="preload" aria-hidden="true"/>
                        </div>
                    </div>
                    <div className="page-main page-next" aria-hidden="true"/>
                    <div className="page-main page-prev" aria-hidden="true"/>
                    <footer className="footer" role="contentinfo"/>
                </div>
            </FrontWrapper>
        )
    }
}

export default index
