import React from "react";
import Home from "../components"
import {graphql} from "gatsby";
import {connect} from "react-redux";
import SEO from "../components/SEO";

const HomePage = (props) =>{
    return(
        <React.Fragment>
            <SEO {...(props?.data?.seo?.frontmatter || {})}/>
            <Home {...props} fields={props?.data?.[props.commonData.locale.locale]?.frontmatter || {}} />
        </React.Fragment>
    )
};

const mapStateToProps = (props) => {
    return props;
};
export default connect(mapStateToProps, null)(HomePage);

export const query = graphql`
fragment HomeFragment on MarkdownRemark {
    frontmatter {
      activities {
        description
        img{
            publicURL
        }
        title
      }
      title
      tour {
        btnText
        description
        title
        videoLink
      }
      ourValues {
        description
        title
        values {
          description
          img{
            publicURL
          }
          title
        }
      }
      philosophy {
        description
        title
      }
      services {
        description
        serviceList {
          description
          title
        }
        title
      }
      vision {
        description
        title
        img{
            publicURL
        }
      }
      mission {
        description
        img{
            publicURL
        }
        title
      }
      partners {
        partnerImages {
          url
          img{
            publicURL
          }
        }
        title
      }
      leadingEdge {
        description
        img{
            publicURL
        }
        items {
          img{
            publicURL
          }
          title
        }
        title
      }
    }
  }
  query HomeQuery {
  seo: markdownRemark(frontmatter: {templateKey: {eq: "home-seo"}}) {
        frontmatter {
            title
            description
        }
  }
    en: markdownRemark(frontmatter: {templateKey: {eq: "en-home"}}) {
   ...HomeFragment
  }
   de: markdownRemark(frontmatter: {templateKey: {eq: "de-home"}}) {
   ...HomeFragment
  }
   es: markdownRemark(frontmatter: {templateKey: {eq: "es-home"}}) {
   ...HomeFragment
  }
   fr: markdownRemark(frontmatter: {templateKey: {eq: "fr-home"}}) {
   ...HomeFragment
  }
   he: markdownRemark(frontmatter: {templateKey: {eq: "he-home"}}) {
   ...HomeFragment
  }
   it: markdownRemark(frontmatter: {templateKey: {eq: "it-home"}}) {
   ...HomeFragment
  }
   ja: markdownRemark(frontmatter: {templateKey: {eq: "ja-home"}}) {
   ...HomeFragment
  }
   zh: markdownRemark(frontmatter: {templateKey: {eq: "zh-home"}}) {
   ...HomeFragment
  }
  }
`;